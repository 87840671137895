import React, { useEffect, useState } from "react";
import "./About.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FirstSection from "../../components/FirstSection";
import { useTranslation } from "react-i18next";
import SecondSection from "./partial/SecondSection";
import ThirdSection from "./partial/ThirdSection";
import FourthSection from "./partial/FourthSection";
import FifthSection from "./partial/FifthSection";
import SixthSection from "./partial/SixthSection";
import SeventhSection from "./partial/SeventhSection";
import { UseCallApi } from "../../helpers/CallApi";
import { Helmet } from "react-helmet";
import Animation from "../../helpers/Animation";

function About() {
  const { t, i18n } = useTranslation();
  const [about, setAbout] = useState<any>([]);
  const callApi = UseCallApi();
  const getData = async () => {
    const data: any = await callApi({
      route: "abouts",
      method: "GET",
    });
    let transformedData = data.data[0];
    if (i18n.language === "en") {
      transformedData.content = transformedData.content_en;
      transformedData.paragraph1 = transformedData.paragraph1_en;
      transformedData.paragraph2 = transformedData.paragraph2_en;
      transformedData.paragraph3 = transformedData.paragraph3_en;
      transformedData.paragraph4 = transformedData.paragraph4_en;
      transformedData.textClient = transformedData.textClient_en;
      transformedData.textProject = transformedData.textProject_en;
      transformedData.textQuality = transformedData.textQuality_en;
      transformedData.textTeam = transformedData.textTeam_en;
      transformedData.textCompany = transformedData.textCompany_en;
    }

    setAbout(transformedData);
  };
  useEffect(() => {
    getData();
  }, [i18n.language]);
  // console.log(about);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prestacia - {t("about.headers.title")} </title>
        {i18n.language === "en" ? (
          <meta
            name="description"
            content="Our dedicated team ensures syndicate management, professional cleaning, customized layout, furnishing, expertise, and quality services for customer satisfaction and a reliable experience."
          />
        ) : (
          <meta
            name="description"
            content="Notre équipe dédiée assure la gestion syndicale, le nettoyage professionnel, l'aménagement sur mesure, l'ameublement, l'expertise et des services de qualité pour la satisfaction client et une expérience fiable."
          />
        )}
        <meta
          name="twitter:description"
          content="Bénéficiez de notre savoir-faire en gestion de copropriété, incluant services de nettoyage, aménagement et ameublement. Confiez-nous la gestion de votre patrimoine immobilier."
        />
        <meta
          name="keywords"
          content="Gestion syndicale, Copropriété, Administration immobilière, Assemblée générale, Charges communes, Comptabilité syndic, Suivi des travaux, Relation propriétaire-locataire, Règlement intérieur, Juridique immobilier, Syndicate management, Condominium management, Real estate administration, General meeting, Common expenses, Syndicate accounting, Maintenance tracking, Owner-tenant relations, Internal regulations, Real estate legal matters"
        />
      </Helmet>

      {/* <Navbar /> */}
      <Animation>
        <FirstSection title={t("about.headers.title")} />
        <SecondSection p={about.content} />
        <ThirdSection
          p1={about.paragraph1}
          p2={about.paragraph2}
          p3={about.paragraph3}
          p4={about.paragraph4}
        />
        <FourthSection />
        <FifthSection />
        <SixthSection
          client={about.textClient}
          project={about.textProject}
          quality={about.textQuality}
        />
        <SeventhSection team={about.textTeam} company={about.textCompany} />
        <Footer />
      </Animation>
    </div>
  );
}

export default About;
