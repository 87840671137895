import React from "react";
import map from "../../assets/images/icons/location2.png";
import circle from "../../assets/images/icons/Ellipse.png";
import circl2 from "../../assets/images/icons/Group 67.png";
import points from "../../assets/images/icons/points.png";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { motion } from "framer-motion";

type Props = {
  data: any;
};
const Detail: React.FC<Props> = ({ data }) => {
  // console.log(data);
  const isDesktop = () => {
    return window.innerWidth >= 768;
  };

  const images = [
    ...(data?.images?.length
      ? data.images.map(({ image_url, id }: any) => ({ image_url, id }))
      : []),
    ...(data?.image_url
      ? [{ image_url: data.image_url, id: data.image_url }]
      : []),
  ];

  return (
    <div className="row mb-5 position-relative">
      {isDesktop() ? (
        <div className="col-md-6 col-lg-7 col-m-12 position-relative d-flex gap-3 flex-nowrap overflow-x-auto overflow-y-hidden py-3 shadow-sm rounded">
          {images.slice(0, images.length - 1)?.map((image: any) => (
            <img
              src={image?.image_url}
              key={image?.id}
              className=" rounded-3 otherImage"
              alt=""
            />
          ))}
          {!!images.length && (
            <img
              src={images[images.length - 1].image_url}
              className="rounded-3  globalImage"
              alt=""
            />
          )}
        </div>
      ) : (
        <motion.div
          layout
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "100%" }}
          transition={{ duration: 0.5, delay: 1.5 }}
          className="col-md-6 col-lg-7 col-m-12 position-relative d-flex gap-3 flex-nowrap overflow-x-auto overflow-y-hidden py-3 shadow-sm rounded"
        >
          {!!images?.length && (
            <Swiper
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              loop={true}
              slidesPerView={1}
              autoplay={{
                delay: 1500,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 4,
                },
              }}
              modules={[Autoplay]}
              style={{ minHeight: "200px" }}
            >
              {images.map((image: any) => (
                <SwiperSlide>
                  <img
                    src={image?.image_url}
                    key={image?.id}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </motion.div>
      )}

      <div className="col-md-6 col-lg-5 col-m-12  d-flex flex-column justify-content-between">
        <div>
          <h3 className="respheader">{data?.name}</h3>
          <h5 className="fontText header_font_size">{data?.category?.name}</h5>
        </div>
        <p className="fontText p_font_size">{data?.description}</p>
        <div className="shadow  p-3 rounded-1">
          <div className="d-flex ">
            <i>
              <img src={map} alt="" />
            </i>

            <p className="fontText adresse_font_size">{data?.address}</p>
          </div>
          <p className="fontText textBuget ps-2">{data?.budget} DH</p>
        </div>
      </div>
      <img
        src={circle}
        className="circleDetailPRoject position-absolute"
        alt=""
      />
      <img
        src={circl2}
        className="circle2DetailPRoject position-absolute"
        alt=""
      />
      <img
        src={points}
        className="pointsDetailPRoject position-absolute"
        alt=""
      />
    </div>
  );
};

export default Detail;
