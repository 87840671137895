import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import Save from "../../../assets/images/icons/Save.png";
import { UseCallApi } from "../../../helpers/CallApi";
import { useTranslation } from "react-i18next";
import { truncateDescription } from "../../../helpers/TruncateDescription";
import { NavLink } from "react-router-dom";
import CustomTab from "./CustomTab";

type Props = {
  setSelectedCategory: (category: string) => void;
  projects: any[];
  selectedCategory: string;
  page: any;
  setPage: any;
};

const CardComponent: React.FC<Props> = ({
  selectedCategory,
  projects,
  setPage,
  page,
  setSelectedCategory,
}) => {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState<any[]>([]);
  const [visibleItemCount, setVisibleItemCount] = useState<number>(3);

  // useEffect(() => {
  //   const initialData = cardData.filter(
  //     (card: any) => card.category.slug == "gestion-syndic"
  //   );
  //   setFilteredData(initialData);
  // }, []);

  // useEffect(() => {
  //   setFilteredData("gestion-syndic");
  // }, [setFilteredData]);

  useEffect(() => {
    const filteredDataa = projects.filter(
      (card: any) => card.category.slug == selectedCategory
    );
    setFilterData(filteredDataa);
  }, [selectedCategory]);

  useEffect(() => {
    setFilterData(projects);
  }, [projects]);

  const handleReadMore = () => {
    setVisibleItemCount((prevCount) => prevCount + 3);
  };

  return (
    <Container className="w-100">
      <>
        <CustomTab setSelectedCategory={setSelectedCategory} />
        <Row>
          {filterData.length > 0 &&
            filterData
              .slice(0, visibleItemCount)
              .map((card: any, index: number) => (
                <Col key={card.id} lg={3} md={6} sm={12}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }} // Initial animation properties
                    animate={{ opacity: 1, y: 0 }} // Animation properties when item is visible
                    transition={{ delay: index * 0.1 }} // Apply delay based on index
                  >
                    <Card
                      style={{
                        margin: "10px",
                        position: "relative",
                        height: "max-content",
                      }}
                    >
                      <NavLink
                        to={`/detail-project/${card.slug}`}
                        className="nav-link "
                        style={{ cursor: "pointer" }}
                      >
                        <Card.Img
                          variant="top"
                          src={card.image_url}
                          style={{ width: "100%", height: "12rem" }}
                        />
                        <Card.Body>
                          <Card.Text className="fontText text_size">
                            {truncateDescription(card.description, 15)}
                          </Card.Text>
                          <div className="d-flex justify-content-between align-items-end">
                            <div className="align-items-bottom my-0">
                              <p className="my-0 text1 fntSubHeader text_size">
                                {card.budget} dh
                              </p>
                              <p className="my-0 fntSubHeader text-capitalize text_size">
                                {card.city}{" "}
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </NavLink>
                    </Card>
                  </motion.div>
                </Col>
              ))}
        </Row>
        {visibleItemCount < filterData.length && (
          <div className="text-center mt-3">
            <a className="CustomLink mt-2 mt-md-4" onClick={handleReadMore}>
              {t("buttons.readMore")}
            </a>
          </div>
        )}
      </>
    </Container>
  );
};

export default CardComponent;
