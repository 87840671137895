import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./DetailProject.css";
import { UseCallApi } from "../../helpers/CallApi";
import Detail from "./Detail";
import ListeImage from "./ListeImage";
import OtherProject from "./OtherProject";
import FifthSection from "../Home/FifthSection";
import Footer from "../../components/Footer";
import Animation from "../../helpers/Animation";

function DetailProject() {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<any>([]);
  const callApi = UseCallApi();

  const getData = async () => {
    setIsLoading(true);

    const data: any = await callApi({
      route: `projects/${slug}`,
      method: "GET",
    });
    let transformedData = data;
    if (i18n.language === "en") {
      transformedData.name = data.name_en;
      transformedData.city = data.city_en;
      transformedData.description = data.description_en;
    }
    setData(transformedData);
  };
  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [slug, i18n.language]);
  return (
    <div>
      <Animation>
        <div className="container detailProject mt-5 px-4">
          <div className="mb-5 wrapper">
            <h6 className="refSubHeader refiner">
              {`${t("detailProject.headers.title")}`.toUpperCase()}
              <div className="marker"></div>
            </h6>
          </div>

          {!isLoading ? (
            <></>
          ) : (
            <>
              <Detail data={data} />
              <OtherProject id={data?.category?.id} />
            </>
          )}
        </div>
        <FifthSection />
        <Footer />
      </Animation>
    </div>
  );
}

export default DetailProject;
