import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import FirstSection from "../../components/FirstSection";
import Footer from "../../components/Footer";
import img1 from "../../assets/images/cleaning.png";
import "./Services.css";
import { useTranslation } from "react-i18next";
import FifthSection from "../Home/FifthSection";
import { UseCallApi } from "../../helpers/CallApi";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Animation from "../../helpers/Animation";
import arrow from "../../assets/images/icons/arrow.png";

type Props = {
  service: any;
};

const Service: React.FC<Props> = ({ service }) => {
  const [services, setServices] = useState([]);
  const [activeService, setActiveService] = useState(""); // State to track active service
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split("/");

  // console.log(pathname);

  const handleServiceClick = (slug: any) => {
    setActiveService(slug);
  };
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const callApi = UseCallApi();

  const getData = async () => {
    const data = await callApi({
      route: "services",
      method: "GET",
    });
    let transformedData = data.data;
    if (i18n.language === "en") {
      transformedData = transformedData.map((tab: any) => ({
        ...tab,
        name: tab.name_en,
        slug: tab.slug,
      }));
    }
    setServices(transformedData);
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prestacia - {t("menu.services")} </title>
        {i18n.language === "en" ? (
          <meta
            name="description"
            content="The 'Contact' page of our property management website offers real estate solutions. Contact us for professional and efficient services."
          />
        ) : (
          <meta
            name="description"
            content="La page 'Contact' de notre site de gestion syndic offre des solutions immobilières. Contactez-nous pour des services professionnels et efficaces."
          />
        )}
        <meta
          name="twitter:description"
          content="La page 'Contact' de notre site de gestion syndic offre des solutions immobilières. Contactez-nous pour des services professionnels et efficaces."
        />
      </Helmet>

      {/* <Navbar /> */}
      <Animation>
        <FirstSection title={t("menu.services")} />
        {service &&
          service.rowservices &&
          service.rowservices.length > 0 &&
          service.rowservices.map((serv: any, index: number) => {
            return (
              <section
                className={`espaceSection d-flex  sectionCleaning row ${
                  index % 2 === 0 ? "flex-row-reverse" : ""
                }`}
              >
                <div className="col-sm-12 col-ls-6 col-md-6">
                  <div
                    className={`d-flex justify-content-between  ${
                      index === 0 ? "headerServic" : ""
                    }`}
                  >
                    <div className="position-relative">
                      <h2 className="headerService ">
                        {index == 0
                          ? i18n.language === "en"
                            ? service.name_en
                            : service.name
                          : ""}
                      </h2>
                      {index === 0 && (
                        <img
                          src={arrow}
                          className="arrow position-absolute"
                          alt=""
                          id="arrow"
                        />
                      )}
                    </div>
                    {index == 0 && (
                      <div className="dropdown dropdown-menu-end  ">
                        <button
                          className="rounded rounded-circle btn text-danger p-0 border-danger  border border-2  dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          style={{
                            height: "30px",
                            width: "30px",
                            marginBottom: "9px",
                          }}
                          aria-expanded="false"
                        ></button>
                        <ul
                          className="dropdown-menu shadow-lg px-3 dropDownListService"
                          aria-labelledby="dropdownMenuButton1"
                          style={{
                            // top: "18%",
                            border: "none",
                            borderRight: "2px solid red",
                            borderTop: "2px solid red",
                            borderLeft: "2px solid white",
                            borderBottom: "2px solid white",
                            borderTopRightRadius: "20px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "20px",

                            // borderTop: "0",
                            // borderWidth: "1px 2px 3px 4px",
                          }}
                        >
                          {services.map((tab: any) => (
                            <li
                              key={tab.slug}
                              className={`dropdown-item  listServiceDropDown py-sm-0 py-md-3  ${
                                activeService === tab.slug ? "active" : ""
                              }`}
                              style={{ color: "#A9A9A9" }}
                            >
                              <NavLink
                                className={`nav-link ${
                                  pathParts[2] === tab.slug ? "active" : ""
                                } `}
                                to={`/services/${tab.slug}`}
                                // onClick={() => handleServiceClick(tab.slug)} // Handle click event
                              >
                                {tab.name.toUpperCase()}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div
                    className="overflow-auto p-4 borders font_responsive height_res"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        i18n.language === "en" ? serv.content_en : serv.content
                      ),
                    }}
                  />
                </div>
                <div className="col-sm-12 col-ls-6 col-md-6">
                  <img
                    src={serv.image_url}
                    alt="cleaning prestacia "
                    width={"90%"}
                    className="h-100"
                  />
                </div>
              </section>
            );
          })}
        <FifthSection />
        <Footer />
      </Animation>
    </div>
  );
};

export default Service;
