import React, { useEffect } from "react";
import comp from "../../../assets/images/Compagnie.png";
import hand from "../../../assets/images/HandAbout.png";
import { useTranslation } from "react-i18next";
import semicircle from "../../../assets/images/icons/elepse2.png";
import circle from "../../../assets/images/icons/Group 67.png";
import point from "../../../assets/images/icons/points.png";
import AOS from "aos";
import "aos/dist/aos.css";
type Props = {
  team: any;
  company: any;
};
const SeventhSection: React.FC<Props> = ({ team, company }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  return (
    <div className="SeventhSectionAbout   d-flex flex-column align-items-center position-relative mb-4">
      <div className="first_div">
        <h2>
          <h2>{t("about.headers.title")}</h2>
        </h2>
        <div></div>
      </div>

      <div className=" mt-5   gap-4  d-flex justify-content-center flex-column align-items-center descreptionComponant">
        <div
          data-aos="fade-right"
          data-aos-delay="200"
          className="d-flex  aboutSectionHistoire"
        >
          <div className="w-75">
            <h4>{t("about.headers.1")}</h4>
            <p>{team}</p>
          </div>
          <div>
            <img src={hand} alt="" />
          </div>
        </div>

        <div
          data-aos="fade-left"
          data-aos-delay="400"
          className="d-flex  aboutSectionHistoire aboutSectionHistoire2"
        >
          <div>
            <img src={comp} alt="" />
          </div>
          <div className="w-75">
            <h4>{t("about.headers.2")}</h4>
            <p>{company}</p>
          </div>
        </div>
      </div>
      <img src={semicircle} className="semicircle position-absolute" alt="" />
      <img src={point} className="point position-absolute  " alt="" />
      <img src={circle} className="circle position-absolute" alt="" />
      <img src={point} className="pointTop position-absolute" alt="" />
    </div>
  );
};

export default SeventhSection;
