import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/index.css";
import { I18nextProvider } from "react-i18next";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import ReactGA from "react-ga"; // Import React GA
import enTranslation from "./translations/en/translation.json";
import frTranslation from "./translations/fr/translation.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Initialize React GA with your tracking ID
// ReactGA.initialize("G-3S1473BYNJ");

// Set up i18next
const savedLng = localStorage.getItem('i18nextLng') || "fr";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
  lng: savedLng,
  fallbackLng: savedLng,
  interpolation: {
    escapeValue: false,
  },
})

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <ToastContainer />
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        
        <App />
        
      </React.StrictMode>
    </I18nextProvider>
  </>
);

// Track initial page view
// ReactGA.pageview(window.location.pathname + window.location.search);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
