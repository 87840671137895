import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseCallApi } from "../../helpers/CallApi";
import ListeImage from "./ListeImage";
import circle2 from "../../assets/images/icons/Group 67.png";
import Ellipse8 from "../../assets/images/icons/Ellipse 8.png";
import PATTERN from "../../assets/images/icons/PATTERN.png";

import points from "../../assets/images/icons/points.png";
type Props = {
  id: number;
};
const OtherProject: React.FC<Props> = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>([]);
  const callApi = UseCallApi();
  const getData = async () => {
    const data: any = await callApi({
      route: `projects/category/${id}`,
      method: "GET",
    });
    let transformedData = data.data;
    if (i18n.language === "en") {
      transformedData = transformedData.map((tab: any) => ({
        ...tab,
        name: tab.name_en,
        city: tab.city_en,
      }));
    }
    setData(transformedData);
  };
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id, i18n.language]);
  return (
    <div>
      <div className="first_div mb-4">
        <h3 className="respheader">
          {`${t("detailProject.headers.1")}`.toUpperCase()}
        </h3>
        <div></div>
      </div>
      <br></br>
      <ListeImage data={data} />
      <img
        src={points}
        alt=""
        className="position-absolute pointsOtherProject"
      />
      <img
        src={circle2}
        alt=""
        className="position-absolute circleOtherProject"
      />
      <img
        src={PATTERN}
        alt=""
        className="position-absolute PATTERNOtherProject"
      />
      <img
        src={Ellipse8}
        alt=""
        className="position-absolute llipse8OtherProject"
      />
    </div>
  );
};

export default OtherProject;
