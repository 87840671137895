import React from "react";
import eleps from "../../../assets/images/icons/Ellipse.png";
import eleps2 from "../../../assets/images/icons/Group 67.png";
import circles from "../../../assets/images/icons/Group 63.png";
import { useTranslation } from "react-i18next";
type Props = {
  p: any;
};
const SecondSection: React.FC<Props> = ({ p }) => {
  const { t } = useTranslation();
  return (
    <div className="second_section_about d-flex  flex-column align-items-center">
      <div className="first_div">
        <div></div>
        <h2 className=" my-2 my-md-3">{t("about.headers.3")} </h2>
      </div>

      <p className="text-center w-50 firstP">{p}</p>
      <img src={eleps} alt="" className="img eleps" />
      <img src={eleps2} alt="" className="img eleps2" />
      <img src={circles} alt="" className="eleps3" />
    </div>
  );
};

export default SecondSection;
