import clsx from "clsx";
import React from "react";
import "./input.css";

type Prop = {
  label?: string;
  formik: any;
  isUserLoading: boolean;
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
};

const Textarea: React.FC<Prop> = ({
  formik,
  isUserLoading,
  name,
  placeholder,
  label,
  isRequired = false,
  isDisabled = false,
}) => {
  return (
    <div className="input-wrapper">
      {label && (
        <label className={` ${isRequired ? "required" : ""} fw-bold fs-6 `}>
          {label}
        </label>
      )}
      <label className={` ${isRequired ? "required" : ""} fw-bold fs-6 mb-2`}>
        {label}
      </label>

      <textarea
        placeholder={placeholder}
        {...formik.getFieldProps(name)}
        name={name}
        cols={1}
        rows={1}
        className={clsx(
          "customInputs ",
          { "is-invalid": formik.touched[name] && formik.errors[name] },
          {
            "is-valid": formik.touched[name] && !formik.errors[name],
          }
        )}
        autoComplete="off"
        disabled={formik.isSubmitting || isUserLoading || isDisabled}
      />
      {formik.submitCount > 0 &&  formik.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik.errors[name]}</span>
          </div>
        </div>
      )}
      {/* end::Textarea */}
    </div>
  );
};

export default Textarea;
