import React from "react";
import ligne from "../../../assets/svg/line.svg";
import icon1 from "../../../assets/images/icons/Isolation_Mode (1).png";
import icon2 from "../../../assets/images/icons/OBJECTS.png";
import icon3 from "../../../assets/images/icons/objects (1).png";
import icon4 from "../../../assets/images/icons/background (1).png";
import { useTranslation } from "react-i18next";

function ThirdSection() {
  const { t } = useTranslation();
  return (
    <div className="section_tree_home">
      <div className="first_div">
        <h2 id="header2">{t("home.headers.3")}</h2>
        <div className="white_line"></div>
      </div>

      <div className="icons gap-4 w-75 text-center">
        <div className="liste_card">
          <div className="img">
            <img src={icon1} alt="" />
          </div>
          <p>{t("home.qualities.1")}</p>
        </div>
        <img src={ligne} className="ligne_image" alt="" />
        <div className="liste_card">
          <div className="img">
            <img src={icon2} alt="" />
          </div>
          <p>{t("home.qualities.2")}</p>
        </div>
        <img src={ligne} className="ligne_image" alt="" />
        <div className="liste_card">
          <div className="img">
            <img src={icon3} alt="" />
          </div>
          <p>{t("home.qualities.3")}</p>
        </div>
        <img src={ligne} className="ligne_image" alt="" />
        <div className="liste_card">
          <div className="img">
            <img src={icon4} alt="" />
          </div>
          <p>{t("home.qualities.4")}</p>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
