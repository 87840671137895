import React from "react";
import { useTranslation } from "react-i18next";
import phone from "../../../assets/images/icons/small-phone.svg";
import map from "../../../assets/images/icons/small-location.svg";
import email from "../../../assets/images/icons/small-email.svg";
import points from "../../../assets/images/icons/points.png";
import circles from "../../../assets/images/icons/circles.png";
import eleps from "../../../assets/images/icons/Ellipse.png";
import ContactFormSecondary from "../../../components/ContactFormSecondary";

function ThirdSection() {
  const { t, i18n } = useTranslation();

  return (
    <div className="thirdSectionContact espaceSection d-flex justify-content-center flex-wrap position-relative">
      <img src={points} alt="" className="points" />
      <img src={eleps} alt="" className="eleps" />
      <div className="col-lg-5 col-md-12 col-sm-12 py-0 px-5 p-md-5">
        <ContactFormSecondary />
      </div>
      <img src={circles} alt="" className="circles" />
      <div className="col-lg-5 col-md-12 col-sm-12 secondFlex py-0 px-5 p-md-5">
        <h1 className="mb-0 mb-md-4">{t("contact.headers.4")}</h1>
        <p className="mb-4 text">{t("contact.text.1")}</p>
        <div className="mb-4 secondtext">
          <i>
            <img src={map} alt="" />
          </i>{" "}
          <a target="_blank" href="https://maps.app.goo.gl/1QtEa2yZ7RonPsbr5">
            {" "}
            {t("contact.text.2")}
          </a>
        </div>
        <div className="mb-4 secondtext">
          <i>
            <img src={phone} alt="" />
          </i>
          <a href="tel:+2126  614 - 22 646">06 614 - 22 646 </a>
        </div>
        <div className="mb-4 secondtext">
          <i>
            <img src={phone} alt="" />
          </i>
          <a href="tel:+2126  614 - 12 845">06 614 - 12 845 </a>
        </div>
        <div className="mb-4 secondtext">
          <i>
            <img src={email} alt="" />
          </i>
          <a href="mailto:contact@prestacia.com"> contact@prestacia.com</a>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
