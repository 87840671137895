import clsx from "clsx";
import React from "react";
import "./inputsecondary.css";

type Prop = {
  label?: string;
  formik: any;
  isUserLoading: boolean;
  name: string;
  type: string;
  placeholder?: any;
  isRequired?: boolean;
  isDisabled?: boolean;
};
const InputSecondary: React.FC<Prop> = ({
  formik,
  isUserLoading,
  name,
  type,
  placeholder,
  label,
  isRequired = false,
  isDisabled = false,
}) => {
  return (
    <div className="input-wrapper">
      {label && (
        <label className={` ${isRequired ? "required" : ""} fw-bold fs-6 `}>
          {label}
        </label>
      )}

      <input
        placeholder={`${placeholder}`}
        {...formik.getFieldProps(name)}
        type={type}
        name={name}
        className={clsx(
          "customInputsecondary w-100",
          // "form-control form-control-solid ",
          { "is-invalid": formik.submitCount > 0 && formik.errors[name] },
          {
            "is-valid": formik.submitCount > 0 && !formik.errors[name],
          }
        )}
        autoComplete="off"
        disabled={formik.isSubmitting || isUserLoading || isDisabled}
      />
      {formik.submitCount > 0 && formik.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik.errors[name]}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </div>
  );
};

export default InputSecondary;
