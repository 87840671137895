import React from "react";
import Navbar from "../../components/Navbar";
// import FirstSection from "./partial/FirstSection";
import "./Home.css";
import SecondSection from "./partial/SecondSection";
import ThirdSection from "./partial/ThirdSection";
import FourthSection from "./partial/FourthSection";
import FifthSection from "./FifthSection";
import ContactForm from "../../components/ContactForm";
import ConatctSection from "./partial/ConatctSection";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import FirstSection from "../../components/FirstSection";
import { useTranslation } from "react-i18next";
import Animation from "../../helpers/Animation";
import larrow from "../../../assets/images/icons/arrow-left.png";

function Home() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prestacia - Accueil </title>
        <meta
          name="description"
          content="Bénéficiez de notre savoir-faire en gestion de copropriété, incluant services de nettoyage, aménagement et ameublement. Confiez-nous la gestion de votre patrimoine immobilier."
        />
        <meta
          name="twitter:description"
          content="Bénéficiez de notre savoir-faire en gestion de copropriété, incluant services de nettoyage, aménagement et ameublement. Confiez-nous la gestion de votre patrimoine immobilier."
        />
        <meta
          name="keywords"
          content="Gestion syndicale, Copropriété, Administration immobilière, Assemblée générale, Charges communes, Comptabilité syndic, Suivi des travaux, Relation propriétaire-locataire, Règlement intérieur, Juridique immobilier, Syndicate management, Condominium management, Real estate administration, General meeting, Common expenses, Syndicate accounting, Maintenance tracking, Owner-tenant relations, Internal regulations, Real estate legal matters"
        />
      </Helmet>
      {/* <Navbar /> */}
      <Animation>
        <FirstSection
          title={t("home.headers.title")
            .split("\n")
            .map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <Footer />
        {/* <ConatctSection /> */}
      </Animation>
    </div>
  );
}

export default Home;
