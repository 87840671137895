import React from "react";
import house from "../../../assets/images/icons/house.png";
import phone from "../../../assets/images/icons/phone.png";
import email from "../../../assets/images/icons/email.png";
import { useTranslation } from "react-i18next";
function SecondSection() {
  const { t, i18n } = useTranslation();

  return (
    <div className="second_section_contact espaceSection">
      <div className="">
        <div className="border-0">
          <a target="_blank" href="https://maps.app.goo.gl/1QtEa2yZ7RonPsbr5">
            <img src={house} alt="" />
          </a>
        </div>
        <p>{t("contact.headers.2")}</p>
      </div>
      <div>
        <div className="border-0">
          <a href="tel:+2126  614 - 22 646">
            <img src={phone} alt="" />
          </a>
        </div>
        <p>{t("contact.headers.3")}</p>
      </div>
      <div>
        <div className="border-0">
          <a href="mailto:contact@prestacia.com">
            <img src={email} alt="" />
          </a>
        </div>
        <p>{t("contact.headers.title")}</p>
      </div>
    </div>
  );
}

export default SecondSection;
