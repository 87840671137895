import React, { useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { errorToast, successToast } from "../helpers/Toasts";
import { UseCallApi } from "../helpers/CallApi";
import { useFormik } from "formik";
import InputSecondary from "../layouts/InputSecondary";
import TextareaSecondary from "../layouts/TextareaSecondary";
function ContactFormSecondary() {
  const editUserSchema = Yup.object().shape({
    email: Yup.string().email("Email invalide").required("L'email est requis"),
    message: Yup.string().required("Le message est requis"),
    name: Yup.string()
      .min(3, "Au moins 3 caractères")
      .max(50, "Maximum 50 caractères")
      .required("Le nom est requis"),
    // image: Yup.mixed().required('Le fichier est requis'),
  });
  const [userForEdit] = useState<any>({});
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setIsUserLoading(true);
      try {
        const response = await callApi({
          route: "contact",
          method: "POST",
          body: values,
        });
        if (response) {
          successToast("Votre message a bien été envoyé");
          formik.setFieldValue("name", "");
          formik.setFieldValue("email", "");
          formik.setFieldValue("message", "");
          // resetForm();
        }
      } catch (ex: any) {
        if (ex.response && ex.response.data && ex.response.data.error) {
          const detailedError = ex.response.data.error;
          console.error("Detailed Error:", detailedError);
          errorToast(detailedError);
        } else {
          console.error("Generic Error:", ex.message);
          errorToast("An error occurred. Please try again.");
        }

        console.error(ex);
      } finally {
        setSubmitting(false);
        setIsUserLoading(false);
      }
    },
  });
  const { t } = useTranslation();
  const callApi = UseCallApi();
  const [isUserLoading, setIsUserLoading] = useState(false);

  return (
    <div className="contact-form-secondary container position-relative contact_div p-5">
      <div className="d-flex flex-column gap-5 position-relative w-100 div_form">
        <InputSecondary
          type="text"
          name="name"
          formik={formik}
          placeholder={`${t("labels.firstName")} & ${t("labels.lastName")}`}
          isUserLoading={isUserLoading}
          isRequired={true}
        />
        <InputSecondary
          type="email"
          name="email"
          formik={formik}
          placeholder={`${t("labels.email")}`}
          isUserLoading={isUserLoading}
          isRequired={true}
        />
        <TextareaSecondary
          name="message"
          formik={formik}
          // label="Description"
          placeholder={`${t("labels.message")}`}
          isUserLoading={isUserLoading}
        />
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className="btn sub w-60"
            disabled={isUserLoading}
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {!isUserLoading ? t("buttons.send") : "Envoi..."}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactFormSecondary;
