// import React from 'react';
// import { animate, motion, Variants } from "framer-motion";

// const Animations: Variants = {
//     initial: {
//         opacity: 1,
//         x: 100
//     },
//     animate: {
//         opacity: 1,
//         x: 0
//     },
//     exit: {
//         opacity: 0,
//         x: -100
//     }
// };

// function Animation({ children }: any) {
//     return (
//         <div>
//             <motion.div variants={Animations} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.5 }}  >
//                 {children}
//             </motion.div>
//         </div>
//     );
// }

// export default Animation;
import React from "react";
import { motion } from "framer-motion";

function Animation({ children }: any) {
  return (
    <motion.main
      className="main__container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 1.5 }}
    >
      {children}
    </motion.main>
  );
}

export default Animation;
