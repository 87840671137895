import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import fb from "../assets/images/icons/facebook.png";
import lin from "../assets/images/icons/linkedin.png";
import ins from "../assets/images/icons/instagram.png";
import yb from "../assets/images/icons/youtube.png";
import eleps from "../assets/images/icons/Ellipse.png";
import CopyRight from "./CopyRight";
import { IoArrowUpOutline } from "react-icons/io5";

import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (!animationTriggered && window.scrollY > window.innerHeight) {
        AOS.init();
        setAnimationTriggered(true);
        window.removeEventListener("scroll", handleScroll);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animationTriggered]);

  return (
    <footer className="pe-0 rposition">
      <div className="d-flex justify-content-center pad mobile-position">
        <div className="bgRowUp rounded-circle ">
          <a
            href="#"
            onClick={() => window.scrollTo(0, 0)}
            className="rowUp fs-2 rounded-circle "
          >
            <IoArrowUpOutline />
          </a>
        </div>
      </div>
      <div className="footer  mt-5  pe-0 d-flex   ">
        <div className="contact-div col-lg-6 col-sm-12 col-md-6 d-flex align-items-center p-4">
          <div className="w-100 contact-sub-div">
            <div className="w-100 mb-5 ">
              <ContactForm />
            </div>
            <div className="mt-5 ">
              <CopyRight />
            </div>
          </div>
          <img src={eleps} className="center_eleps" alt="" />
          <img src={eleps} className="top_left_eleps" alt="" />
          <img src={eleps} className="bottom_left_eleps" alt="" />
        </div>

        <div
          className="backgroun_div col-lg-6 col-sm-12 col-md-6  "
          data-aos={animationTriggered ? "" : "fade-right"}
          data-aos-delay="500"
        >
          <div
            className="bg-white   child_div d-flex flex-column justify-content-space-between align-items-start gap-2"
            data-aos={animationTriggered ? "" : "fade-left"}
            data-aos-delay="1000"
          >
            <div></div>
            <div>
              <h2 className="mb-0">{t("footer.headers.2")}</h2>
            </div>
            <div>
              <a href="https://maps.app.goo.gl/1QtEa2yZ7RonPsbr5">
                102 Rue Smara, Saïdia 63600
              </a>
              {/* <p className="mb-0 mt-0"></p> */}
            </div>
            <div>
              <a href="tel:+2126  614 - 22 646">06 614 - 22 646</a>
              <a href="tel:+2126  614 - 12 845" style={{ display: "block" }}>
                06 614 - 12 845
              </a>
            </div>
            <div>
              <a href="mailto:contact@prestacia.com	">contact@prestacia.com </a>
            </div>
            <div className="SocialMedia  gap-3">
              <i>
                <a
                  href="https://www.facebook.com/profile.php?id=100064132891217&mibextid=ZbWKwL"
                  target="_blank"
                >
                  <img src={fb} alt="" />
                </a>
              </i>
              <i>
                <a href="#" target="_blank">
                  {" "}
                  <img src={lin} alt="" />
                </a>
              </i>
              <i>
                {" "}
                <a
                  href="https://www.instagram.com/prestacia.services?igsh=emkxa3J6b2ZkNmVx"
                  target="_blank"
                >
                  {" "}
                  <img src={ins} alt="" />
                </a>
              </i>
              <i>
                {" "}
                <a href="#" target="_blank">
                  {" "}
                  <img src={yb} alt="" />
                </a>
              </i>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
