import React from "react";
import FirstSection from "../../components/FirstSection";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ScondSection from "./partial/SecondSection";
import SecondSection from "./partial/SecondSection";
import "./Contact.css";
import ThirdSection from "./partial/ThirdSection";
import { Helmet } from "react-helmet";
import Animation from "../../helpers/Animation";

function Contact() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prestacia - {t("menu.contact")} </title>
        {i18n.language === "en" ? (
          <meta
            name="description"
            content="The 'Contact' page of our property management website offers real estate solutions. Contact us for professional and efficient services."
          />
        ) : (
          <meta
            name="description"
            content="La page 'Contact' de notre site de gestion syndic offre des solutions immobilières. Contactez-nous pour des services professionnels et efficaces."
          />
        )}
        <meta
          name="twitter:description"
          content="La page 'Contact' de notre site de gestion syndic offre des solutions immobilières. Contactez-nous pour des services professionnels et efficaces."
        />
        <meta
          name="keywords"
          content="Contact us, property management, real estate solutions, professional services, efficiency, specialized team, Request a quote, Contact, gestion syndic, solutions immobilières, services professionnels, efficacité, équipe spécialisée, Demande de devis"
        />
      </Helmet>

      {/* <Navbar /> */}
      <Animation>
        <FirstSection title={t("menu.contact")} />
        <SecondSection />
        <ThirdSection />
        <Footer />
      </Animation>
    </div>
  );
}

export default Contact;
