import icon1 from "../../../assets/images/icons/holcimlogo.png";
import icon2 from "../../../assets/images/icons/etvLogo.png";
import icon3 from "../../../assets/images/icons/westinLogo.png";
import icon4 from "../../../assets/images/icons/summitlogo.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import circle from "../../../assets/images/icons/circle.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { useEffect, useState } from "react";
import { UseCallApi } from "../../../helpers/CallApi";
const CustomSwiper = () => {
  const [partners, setPartners] = useState<any>([]);
  const callApi = UseCallApi();
  const getData = async () => {
    try {
      const data: any = await callApi({
        route: "partners",
        method: "GET",
      });
      setPartners(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {partners.length > 0 && (
        <Swiper
          className="text-center w-75 swiperHome"
          effect="fade"
          fadeEffect={{
            crossFade: true,
          }}
          loop={true}
          // navigation
          pagination
          slidesPerView={4}
          autoplay={{
            delay: 2000,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400: {
              slidesPerView: 2,
            },
            639: {
              slidesPerView: 4,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {partners.map((partner: any) => (
            <SwiperSlide className="" key={partner.id}>
              <div className="w-100">
                {partner.id === 24 ? (
                  <a
                    href="https://softcactus.ma"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={partner.image_url}
                      alt=""
                      width={100}
                      className="img-size"
                    />
                  </a>
                ) : (
                  <img
                    src={partner.image_url}
                    alt=""
                    width={100}
                    className="img-size"
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default CustomSwiper;
