import React, { useEffect, useState } from "react";
import { UseCallApi } from "../../../helpers/CallApi";
import { useTranslation } from "react-i18next";
type Props = {
  setSelectedCategory: (category: string) => void;
};
const CustomTab: React.FC<Props> = ({ setSelectedCategory }) => {
  const [isActive, setIsActive] = React.useState("Gestion syndic");

  const handleTabClick = (tabName: string, slug: string) => {
    setIsActive(tabName);
    setSelectedCategory(slug);
  };
  const [tabs, setTabs] = useState<any>([]);
  const callApi = UseCallApi();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const getData = async () => {
    try {
      const data: any = await callApi({
        route: "categories",
        method: "GET",
      });
      let transformedData = data.data;
      if (currentLanguage == "en") {
        transformedData = transformedData.map((tab: any) => ({
          ...tab,
          name: tab.name_en,
        }));
      }
      setTabs(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, [currentLanguage]);

  return (
    // <div className="my-3 ">
    <div className="w-100 px-1 ">
      <ul
        className="nav nav-tabs d-flex justify-content-between mb-4 "
        id="myTab"
      >
        {tabs.map((tab: any) => (
          <li className="nav-item nav_center" key={tab.id}>
            <button
              className={`nav-link ${isActive === tab.name && "active"}`}
              aria-current="page"
              onClick={() => handleTabClick(tab.name, tab.slug)}
            >
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomTab;
