import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Service from "../pages/Services/Service";
import DetailProject from "../pages/DetailProject/DetailProject";
import { AnimatePresence } from "framer-motion";
import { UseCallApi } from "../helpers/CallApi";
import Navbar from "../components/Navbar";

const AppRouter: React.FC = () => {
  const [services, setServices] = React.useState([]);
  const callApi = UseCallApi();

  const getData = async () => {
    try {
      const data: any = await callApi({
        route: "services",
        method: "GET",
      });

      let transformedData = data.data;

      setServices(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BrowserRouter>
    <Navbar />
      <AnimatePresence>
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/detail-project/:slug" element={<DetailProject />} />
          {services.map((service: any) => (
            <Route
              path={`/services/${service.slug}`}
              element={<Service service={service} />}
              key={service.slug}
            />
          ))}
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
};

export default AppRouter;
