import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import map from "../../assets/images/icons/MapPin.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

type Props = {
  data: any;
};

const ListeImage: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleItemCount, setVisibleItemCount] = React.useState<number>(4);

  const handleReadMore = () => {
    setVisibleItemCount((prevCount) => prevCount + 4);
  };
  return (
    <div>
      <Grid container spacing={5}>
        {data.slice(0, visibleItemCount).map((item: any) => (
          <Grid item key={item.id} xs={6} md={6} lg={3}>
            <ImageListItem className="imageOtherProject rounded-3 shadow-sm">
              <img
                srcSet={`${item.image_url}`}
                src={`${item.image_url}`}
                alt={item.name}
                loading="lazy"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/detail-project/${item.slug}`)}
                className="rounded-3 shadow-sm"
              />
              <div className="">
                <NavLink
                  to={`/detail-project/${item.slug}`}
                  className="nav-link "
                  style={{ cursor: "pointer" }}
                >
                  <p className="text-center text-capitalize blue_back">
                    {item.city}
                  </p>
                  <p className="text-center blue_back">{item.budget} dh</p>
                </NavLink>
              </div>
              {/* <ImageListItemBar title={item.name} subtitle={item.category.name} /> */}
            </ImageListItem>
          </Grid>
        ))}
      </Grid>
      {visibleItemCount < data.length && (
        <div className="text-center mt-3">
          <a onClick={handleReadMore} className=" read_more">
            {t("buttons.readMore")}
          </a>
        </div>
      )}
    </div>
  );
};

export default ListeImage;
