import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import logo from "./logo.svg";
import AppRouter from "./router/AppRouter";

function App() {
  return <AppRouter />;
}

export default App;
