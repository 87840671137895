import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";

import { Navigation, Pagination, Autoplay } from "swiper/modules";
import circle from "../../../assets/images/icons/circle.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { UseCallApi } from "../../../helpers/CallApi";
import { useTranslation } from "react-i18next";
import { truncateDescription } from "../../../helpers/TruncateDescription";

const CustomSwiper = () => {
  const swiperRefLocal = useRef<any>();
  const navigationPrevRef = useRef<any>(); // Add this line

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  const [service, setService] = useState<any>([]);
  const callApi = UseCallApi();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const getData = async () => {
    try {
      const data: any = await callApi({
        route: "services",
        method: "GET",
      });
      let transformedData = data.data;
      if (currentLanguage == "en") {
        transformedData = transformedData.map((tab: any) => ({
          ...tab,
          name: tab.name_en,
          slug: tab.slug,
        }));
      }
      setService(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, [currentLanguage]);
  const goToPrevSlide = () => {
    swiperRefLocal?.current?.swiper?.slidePrev();
  };
  const goToNextSlide = () => {
    swiperRefLocal?.current?.swiper?.slideNext();
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="position-relative"
    >
      {service.length > 0 && (
        <Swiper
          className="text-center swiperHome"
          effect="fade"
          ref={swiperRefLocal}
          // navigation={{
          //   prevEl: navigationPrevRef.current,
          //   nextEl: navigationPrevRef.current, // Add this line
          // }}
          fadeEffect={{
            crossFade: true,
          }}
          loop={true}
          // navigation
          pagination
          slidesPerView={3}
          autoplay={{
            delay: 2000,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400: {
              slidesPerView: 1,
            },
            639: {
              slidesPerView: 2,
            },
            990: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {service.map((item: any) => (
            <SwiperSlide className="container" key={item.id}>
              <a
                href={`/services/${item.slug}`}
                className="remove-text-decoration"
              >
                <div className=" text-center cutomTab d-flex flex-column  align-items-center cardSwiper mb-3 p-2 p-md-4">
                  <div>
                    <h6 className="mb-0 text-uppercase">
                      {" "}
                      {i18n.language === "en" ? item.name_en : item.name}
                    </h6>
                    <img src={circle} alt="" />
                  </div>
                  <p className="fontText bold m-auto">
                    {truncateDescription(
                      i18n.language === "en"
                        ? item.description_en
                        : item.description,
                      20
                    )}
                  </p>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <button
        className="prev-button position-absolute"
        onClick={goToPrevSlide}
      ></button>
      <button
        className="next-button position-absolute"
        onClick={goToNextSlide}
      ></button>
    </div>
  );
};

export default CustomSwiper;
