import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import frnace from "../assets/images/icons/France.png";
import english from "../assets/images/icons/english.png";
import { useTranslation } from "react-i18next";
import { UseCallApi } from "../helpers/CallApi";

function Navbar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [services, setServices] = useState<any>([]);
  const [activeService, setActiveService] = useState(""); // State to track active service
  const [collapsed, setCollapsed] = useState(true);
  const dropdownRef = useRef<HTMLAnchorElement>(null);

  const closeDropdown = () => {
    if (dropdownRef.current) {
      const dropdownElement = dropdownRef.current as HTMLElement;
      dropdownElement.click();
    }
  };
  // let timeoutId: ReturnType<typeof setTimeout> | null = null;

  // const toggleMobileNavbar = () => {
  //   if (collapsed === true) {
  //     clearTimeout(timeoutId!);
  //     timeoutId = setTimeout(() => {
  //       setCollapsed(false);
  //     }, 5000);
  //   } else {
  //     clearTimeout(timeoutId!);
  //     setCollapsed(true);
  //   }
  // };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleButtonClick = () => {
    toggleCollapsed();
    closeDropdown();
  };
  useEffect(() => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  // const changeLanguage = (lng: any) => {
  //   i18n.changeLanguage(lng);
  // };
  const changeLanguage = (lng: any) => {
    // Check if language preference exists in local storage
    const savedLng = localStorage.getItem("i18nextLng");
    if (savedLng) {
      i18n.changeLanguage(savedLng);
    } else {
      // If not found in local storage, use the provided language
      i18n.changeLanguage(lng);
    }
  };

  const callApi = UseCallApi();

  const getData = async () => {
    try {
      const data = await callApi({
        route: "services",
        method: "GET",
      });
      let transformedData = data.data;
      if (i18n.language === "en") {
        transformedData = transformedData.map((tab: any) => ({
          ...tab,
          name: tab.name_en,
          slug: tab.slug,
        }));
      }
      setServices(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  // UseEffect to update active service when location changes
  useEffect(() => {
    const pathname = location.pathname;
    const activeSlug = services.find((tab: any) =>
      pathname.includes(tab.slug)
    )?.slug;
    setActiveService(activeSlug || "");
  }, [location.pathname, services]);

  return (
    <div>
      <nav className="navbar navHome navbar-expand-lg" id="navbar">
        <div className="container ">
          {!isMobile && <LogoContainer />}

          {isMobile && (
            <LanguageSelector
              isMobile={isMobile}
              changeLanguage={changeLanguage}
            />
          )}

          {isMobile && <LogoContainer />}

          <NavbarToggler
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />

          <div
            className={`collapse navbar-collapse justify-content-center ${
              isMobile ? "position-fixed responsive_nav" : ""
            } ${collapsed ? "" : "show"}`}
            id="navbarNavDropdown"
          >
            <div style={{ height: "100%" }}>
              <ul className="navbar-nav ">
                <li className="nav-item w-7">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to={"/"}
                    onClick={toggleCollapsed}
                  >
                    {t("menu.home")}
                  </NavLink>
                </li>
                <li className="nav-item w-7">
                  <NavLink
                    className="nav-link"
                    to={"/about"}
                    onClick={toggleCollapsed}
                  >
                    {t("menu.about")}
                  </NavLink>
                </li>
                <li className="nav-item dropdown w-7" id="dropdownNavbarList">
                  <NavLink
                    className={`nav-link  dropdown-toggle ${
                      location.pathname.includes("/services") ? "active" : ""
                    }`}
                    id="navbarDropdown"
                    to="/services/*"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ref={dropdownRef}
                  >
                    {t("menu.services")}
                  </NavLink>

                  <ul className="dropdown-menu navbarDropdownDown serviceDopDowsn">
                    {services.map((tab: any) => (
                      <li
                        key={tab.slug}
                        className={`dropdown-item ${
                          activeService === tab.slug ? "active" : ""
                        }`}
                      >
                        <NavLink
                          className="nav-link"
                          to={`/services/${tab.slug}`}
                          onClick={handleButtonClick}
                        >
                          {tab.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="nav-item w-7" id="lastItem">
                  <NavLink
                    className="nav-link px-lg-4"
                    to={"/contact"}
                    onClick={toggleCollapsed}
                  >
                    {t("menu.contact")}
                  </NavLink>
                </li>
                {isMobile && (
                  <li className="nav-item dropdown w-7">
                    <a
                      className="nav-link dropdown-toggle d-none"
                      href="#"
                      id="dropDowantranslate"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="me-2 ">
                        <img
                          src={i18n.language === "fr" ? frnace : english}
                          width={20}
                        />
                      </i>
                      {i18n.language === "fr"
                        ? t("menu.french")
                        : t("menu.english")}
                    </a>
                    <ul
                      className="dropdown-menu "
                      aria-labelledby="dropDowantranslate"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => changeLanguage("fr")}
                        >
                          <i className="me-2">
                            <img src={frnace} alt="" width={20} />
                          </i>
                          {t("menu.french")}{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => changeLanguage("en")}
                        >
                          <i className="me-2">
                            <img src={english} alt="" width={20} />
                          </i>
                          {t("menu.english")}
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>

          {!isMobile && (
            <LanguageSelector
              isMobile={isMobile}
              changeLanguage={changeLanguage}
            />
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;

function LanguageSelector({
  isMobile,
  changeLanguage,
}: {
  isMobile: boolean;
  changeLanguage: (lng: any) => void;
}) {
  const { t, i18n } = useTranslation();
  return (
    <div className="dropdown position-relative button_transaltes">
      <button
        className="btn dropdown-toggle"
        type="button"
        id="languageDropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="me-2 ">
          <img
            className="border border-2 border-white rounded rounded-circle"
            src={i18n.language === "fr" ? frnace : english}
            width={25}
          />
        </i>
        {!isMobile &&
          (i18n.language === "fr" ? t("menu.french") : t("menu.english"))}
      </button>
      <div className="dropdown-menu " aria-labelledby="languageDropdown">
        <button className="dropdown-item" onClick={() => changeLanguage("fr")}>
          <i className="me-2">
            <img src={frnace} alt="" width={20} />
          </i>
          {t("menu.french")}
        </button>
        <button className="dropdown-item" onClick={() => changeLanguage("en")}>
          <i className="me-2">
            <img src={english} alt="" width={20} />
          </i>
          {t("menu.english")}
        </button>
      </div>
    </div>
  );
}

function NavbarToggler({
  collapsed,
  toggleCollapsed,
}: {
  collapsed: boolean;
  toggleCollapsed: () => void;
}) {
  return (
    <button
      className="navbar-toggler toggle_index shadow_disable"
      type="button"
      // data-bs-toggle="collapse"
      // data-bs-target="#navbarNavDropdown"
      // aria-controls="navbarNavDropdown"
      aria-expanded={!collapsed ? "true" : "false"}
      aria-label="Toggle navigation"
      onClick={toggleCollapsed}
    >
      <span className="navbar-toggler-icon" />
    </button>
  );
}

function LogoContainer() {
  return (
    <NavLink to={"/"} style={{ all: "unset" }}>
      <a href="#" className="navbar-brand">
        <img src="/logo.png" alt="logo" />
      </a>
    </NavLink>
  );
}
