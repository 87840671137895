import React, { useEffect, useState } from "react";
import CustomTab from "./CustomTab";
import CardComponent from "./CardCompinant";
import { NavLink } from "react-router-dom";
import points from "../../../assets/images/icons/points.png";

import eleps from "../../../assets/images/icons/elepse2.png";
import eleps8 from "../../../assets/images/icons/Ellipse 8.png";
import { useTranslation } from "react-i18next";
import { UseCallApi } from "../../../helpers/CallApi";

function FourthSection() {
  const [page, setPage] = useState(1);
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [projects, setProjects] = useState<any[]>([]);

  const callApi = UseCallApi();

  const getData = async () => {
    try {
      const data: any = await callApi({
        route: "projects",
        method: "GET",
      });
      let transformedData = data.data;
      if (i18n.language === "en") {
        transformedData = transformedData.map((tab: any) => ({
          ...tab,
          name: tab.name_en,
          city: tab.city_en,
        }));
      }
      setProjects(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  if (!projects.length) {
    return null;
  }

  return (
    <div className="section_four_home ">
      <img src={points} alt="" className="points" />
      <img src={eleps} alt="" className="eleps" />
      <img src={eleps8} alt="" className="eleps_8" />
      <div className="first_div mb-3 pad">
        <h2>{t("home.headers.4")}</h2>
        <div></div>
      </div>

      {/* <CustomTab setFilteredData={setFilteredData} /> */}
      <CardComponent
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        projects={projects}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}

export default FourthSection;
