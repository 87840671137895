import React, { useEffect } from "react";
import icon1 from "../../../assets/images/icons/Group 49.png";
import icon2 from "../../../assets/images/icons/Isolation_Mode.png";
import icon3 from "../../../assets/images/icons/Layer_1.png";
import points from "../../../assets/images/icons/points.png";
import circles from "../../../assets/images/icons/circles.png";
import CountUp, { useCountUp } from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";

// import "./index.css";

import CustomSwiper from "./Caro";
import { useTranslation } from "react-i18next";

function SecondSection() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  useCountUp({
    ref: "counter",
    enableScrollSpy: true,
    scrollSpyDelay: 2000,
    end: 700,
  });

  return (
    <div className="section_two_home">
      <div className="listCard  gap-2 gap-sm-3 gap-md-4 gap-lg-4 gap-xl-4">
        <div className="myCard" data-aos="fade-right" data-aos-delay="500">
          <img src={icon1} alt="" />
          <p className="m-0">{t("home.values.SATISFIED_CLIENTS")}</p>
          <span>
            +
            <CountUp end={300} enableScrollSpy />
          </span>
        </div>
        <div className="myCard" data-aos="fade-left" data-aos-delay="1500">
          <img src={icon3} alt="" />
          <p className="m-0">{t("home.values.SERVICE_QUALITY")}</p>
          <span>
            <CountUp end={100} enableScrollSpy />%
          </span>
        </div>
        <div className="myCard" data-aos="fade-down" data-aos-delay="1000">
          <img src={icon2} alt="" />
          <p className="m-0">{t("home.values.COMPLETE_PROJECT")}</p>
          <span>
            + <CountUp end={722} enableScrollSpy />
          </span>
        </div>
      </div>
      <div className="first_div">
        <h2>{t("home.headers.2")}</h2>
        <div></div>
      </div>

      <img src={points} alt="" className="section_two_image" />
      <img src={circles} alt="" className="section_two_image_circles" />
      <div className="mt-4">
        <CustomSwiper />
      </div>
    </div>
  );
}

export default SecondSection;
