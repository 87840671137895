import React from "react";
import { useTranslation } from "react-i18next";
type Props = {
  title?: any;
};
const FirstSection: React.FC<Props> = ({ title }) => {
  return (
    <>
      <div className="firs_section_home d-flex flex-column align-items-center">
        <h2 className="container-fluid">{title}</h2>
        <div className="dangerLigne-lg w-10"></div>
      </div>
      <div className="dangerLigne w-100 mt-3"></div>
    </>
  );
};

export default FirstSection;
