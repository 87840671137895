import React, { useEffect } from "react";
import icon1 from "../../../assets/images/icons/points.png";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
type Props = {
  p1: string;
  p2: string;
  p3: string;
  p4: string;
};
const ThirdSection: React.FC<Props> = ({ p1, p2, p3, p4 }) => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      style={{ gap: "2rem" }}
      className=" thirdSection_about p-5  d-flex position-relative  justify-content-center d-flex"
    >
      <div className="d-flex flex-column justify-content-between gap-3 listP ">
        <p data-aos="fade-right" data-aos-delay="300">
          {p1}
        </p>
        <p
          data-aos="fade-down"
          data-aos-delay="500"
          style={{ marginBottom: "inherit" }}
        >
          {p2}
        </p>
      </div>
      <div className="background   col-4  flex-shrink-1 "></div>
      <div className="d-flex flex-column justify-content-between gap-3 listP ">
        <p data-aos="fade-left" data-aos-delay="700">
          {p3}
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="900"
          style={{ marginBottom: "inherit" }}
        >
          {p4}
        </p>
      </div>
      <img src={icon1} className="icon" alt="" />
    </div>
  );
};

export default ThirdSection;
