import React from "react";
import { useTranslation } from "react-i18next";
function FourthSection() {
  const { t } = useTranslation();
  return (
    <div className="FourthSectionAbout  d-flex flex-column align-items-center justify-content-center">
      <h2 className="text-white">{t("about.headers.4")}</h2>
    </div>
  );
}

export default FourthSection;
