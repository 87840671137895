import React from "react";
import Rythm from "../../../assets/images/icons/RythmeBlue (2).png";
import Harmonie from "../../../assets/images/icons/Harmonie.png";
import Durabilite from "../../../assets/images/icons/Durabilite.png";
import Confiance from "../../../assets/images/icons/Confiance.png";
import points from "../../../assets/images/icons/points.png";
import Ellipse from "../../../assets/images/icons/Ellipse.png";
import { useTranslation } from "react-i18next";

const FifthSection = () => {
  const { t } = useTranslation();

  return (
    <div className="FifthSectionAbout espaceSection position-relative">
      <i>
        <img src={Rythm} alt="" width={100} />
        <p>{t("home.qualities.1")}</p>
      </i>
      <i>
        <img src={Harmonie} alt="" width={100} />
        <p>{t("home.qualities.2")}</p>
      </i>
      <i>
        <img src={Durabilite} alt="" width={100} />
        <p>{t("home.qualities.3")}</p>
      </i>
      <i>
        <img src={Confiance} alt="" width={100} />
        <p>{t("home.qualities.4")}</p>
      </i>
      <img src={points} className="points position-absolute" alt="" />
      <img src={Ellipse} className="Ellipse position-absolute" alt="" />
    </div>
  );
};

export default FifthSection;
